@import 'components/global.scss';

.footer {

  background-color: #e7e7e7;

  section {
    background-color: transparent;
  }

  span, address {

    display: inline-block;
    clear: both;
    opacity: 0.7;
    text-align: left;
    font-style: normal;
    font-size: 0.8em;

  }

  span {

    margin-bottom: $scale1;

  }

  nav {

    float: left;
    clear: both;
    margin-bottom: $scale;

    a {

      color: #1A1A1A;
      font-weight: 500;
      font-size: 0.9em;
      opacity: 0.7;
      text-decoration: none;
      margin-right: $scale1;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {

        opacity: 1;
        color: $c-primary;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

      }
    }
  }
}
