@import 'components/global.scss';

.wrapper {
  //border-left: 6px solid;
}

.hover {
    &:hover {
        .iconWrap {
            transition: all 0.65s;
            transform: rotateY(360deg);
        }
    }
}

.pointer {
    cursor: pointer;
}

.statTwo {

    position: relative;
    text-align: left;
    min-height: $scale4;
    display: flex;
    flex-direction: row;
    align-items: center;
  
}

.iconWrap {

    width: 60px;
    height: 60px;
    padding: 14px 0;
    border-radius: 4pc;
    text-align: center;
    font-size: 20px;
}
  
  .rosyBrownBorder {
    border-color: $c-rosybrown;
  }
  
  .rosyBrownBg {
    background-color: rgba(188, 143, 143, 0.4);
  }
  
  .rosyBrownIcon {
    color: $c-rosybrown !important;
  }
  
  .shellBlueBorder {
    border-color: $c-shellblue;
  }
  
  .shellBlueBg {
    background-color: rgba(73, 114, 133, 0.4);
  }
  
  .shellBlueIcon {
    color: $c-shellblue !important;
  }
  
  .darkBlueBorder {
    border-color: $c-darkblue;
  }
  
  .darkBlueBg {
    background-color: rgba(58, 68, 136, 0.4);
  }
  
  .darkBlueIcon {
    color: $c-darkblue !important;
  }
  
  .orangeBorder {
    border-color: $c-orange;
  }
  
  .orangeBg {
    background-color: rgba(240, 170, 97, 0.4);
  }
  
  .orangeIcon {
    color: $c-orange !important;
  }

  .content {

    margin-left: 16px;
  
    .value {
  
      font-size: $scale2;
      font-weight: 700;
    
    }
  
    .label {
  
      font-size: 0.85em;
      color: lighten($c-text, 10%);
    
    }
  }

.iconCardWrap {
    border: 1px solid #cdcdcd;
    transition: all 2s ease;
    height: 140px;

    .iconSection {
        padding: 0 50%;
        width: 80px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        .iconBtn {
            font-size: 28px;
            transition: all .2s ease;
        }

        .iconTitle {
            padding-top: 10%;
        }
    }
}



.iconCardWrap:hover {
    .iconSection {
        .iconBtn {
            font-size: 32px;
        }
    }
}

