@import 'components/global.scss';
@import 'components/global/animation.scss';

.loader {

  position: absolute;
  left: 50%;
  top: 50%;
  width: 26px;
  height: 26px;
  transform: translate(-50%, -50%);

}

.center {

  position: absolute;
  width: 22px;
  height: 22px;
  z-index: 10;

}

.orbit {

  $speed: 1.2s;

  position: absolute;
  top: 8px;
  width: 20px;
  z-index: 10;
  -webkit-animation: rotate $speed infinite linear;
  -moz-animation: rotate $speed infinite linear;
  -o-animation: rotate $speed infinite linear;
  animation: rotate $speed infinite linear;

}

.fullScreen {

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  > div {

    z-index: 11;
    transform: none;

  }

  &:before {

    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);

  } 
}
