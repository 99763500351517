.reportHeader {
    font-weight: 600;
}

.reportMenuWrap {
    float: right;
}

.flowDate, .flowTotal {
    font-size: 16px;
    font-weight: 500;
}

.emailSetupFormWrap {
    .btnLoader {
        width: 100%;
    }
}

.multiEmail {
    margin-bottom: 20px;
}

@media (max-width: 768px){
    .reportMenuWrap {
        float: none;
    }

    .exportMenu {
        width: 100%;

        button {
            margin-right: 0 !important;
            width: 100%;
            margin: 10px 0;
        }
    }
}