@import 'components/global.scss';

.nav {

  height: $scale8;
  background-color: transparent;

  > div {

    height: 100%;

  }

  nav {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    a {

      color: #1A1A1A;
      margin-right: $scale;
      text-decoration: none;

      &:last-child {

        margin-right: 0;

      }
    }
  }
}

.logo {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);
  width: 12em !important;

}

.transparent {

  background-image: none;
  background-color: transparent;

}
