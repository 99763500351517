@import 'components/global.scss';

.form {

  > header {

    padding: 0 0 1em 0 !important;

    h2 {

      margin-bottom: $scale/3;

    }

    p {

      color: lighten($c-text, 16%);
      font-size: $scale;

    }
  }

  > a {

    display: block;
    margin-bottom: $scale;

  }
}

.loading {

  opacity: 0.5;

}
