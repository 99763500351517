@import 'components/global.scss';

.input {

  position: relative;
  display: flex;
  flex-direction: column;

  label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 16px;
    line-height: 1;
    left: 16px;
    z-index: 1000;

  }

  &:focus-within label {
    transform: translate(0, 12px) scale(0.8);
  }

}

.textbox {

  display: block;
  position: relative;
  width: 100%;
  color: $c-text;
  font-size: $scale;
  padding: $input-scale;
  margin: 0 0 $scale1;
  background-color: white;
  border: 1px solid $c-border;

  &:not(:focus)::placeholder {
    color: transparent;
  }

  &:focus {

    background-color: #fafafa;

  }

  &:disabled {

    opacity: 0.5;

  }
}

textarea.textbox {

  min-height: $scale10;
  font-family: 'Source Sans Pro', helvetica, arial, sans-serif;

  &.error {
    border-radius: 38px 38px 0 0;
  }
}

.dateWrapper {

  width: 100%;
  
  span {

    width: 2em !important;

  }
}

.success {

  background-size: 0.85em;
  border-color: lighten($c-green, 24%);
  background-position: center right 1em;

}

.successIcon {

  position: absolute;
  top: 26%;
  right: $scale-2;
  margin-top: $scale-1; // label height
  transform: translateY(-50%);

}

.error {

  position: relative;
  color: $c-red;
  margin-bottom: 0;
  border: 1px solid $c-red;
  background-color: lighten($c-red, 38%);
  border-radius: 38px 38px 0 0;
  
}

.warning {

  background: darken($c-orange, 3%);

}


// placeholders
::-webkit-input-placeholder {

  color: lighten($c-text, 24%);

}

::-moz-placeholder {

  color: lighten($c-text, 24%);

}

:-ms-input-placeholder {

  color: lighten($c-text, 24%);

}

:-moz-placeholder {

  color: lighten($c-text, 24%);

}

.error::-webkit-input-placeholder {

  color: $c-red;

}

.error::-moz-placeholder {

  color: $c-red;

}

.error:-ms-input-placeholder {

  color: $c-red;

}

.error:-moz-placeholder {

  color: $c-red;

}
