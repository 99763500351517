@import 'components/global.scss';

.search {

  height: 3.4em;

  input {
    padding: 0.88em 1em;
  }
}

.searchWrap {
  position: relative;
  width: 94%;
  float: left;
}

.fullWidth {
  width: 100%;
}

.clearWrap {
  width: 6%;
  float: left;
  height: 100%;

  button {
    margin: 1px 4px;
    height: 100%;
    width: 100%;
    border: 1px solid #f2f2f2;
    padding: 12px 0;
  }
}

.btn {

  display: block;
  position: absolute;
  right: 1.2em;
  top: 38%;
  z-index: 2;
  width: 1.5em !important;
  height: 1.5em !important;
  cursor: pointer;
  opacity: 0.5;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  background-size: contain;
  background-color: transparent;
  background-position: center center;
  background-image: url("./icons/ico-search.svg");

  &:hover {

    opacity: 1;
    transition: all 0.2s ease-in-out;

  }
}
