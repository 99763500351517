.card {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
    margin: 4px 0;
    min-width: 330px;
    .header {
        margin: 0px 4px 10px 4px;
        padding: 10px 10px 5px 10px;
        border-bottom: 1px solid #cdcdcd;
        font-weight: 600;
    }

    .description {
        padding: 0 10px;
        min-height: 80px;
    }

    .assignee {
        padding: 0 10px;
    }

    .pendingTag {
        padding: 10px;
    }
}

.plrEntry {
    strong {
        margin-right: 5px;
    }
}

.pipelineSection {
    p {
        margin-bottom: 8px;
    }
}

.subTableWrap {
    padding: 10px 0 40px 0;
}

.utilityInfoSection {
    padding: 20px 0 20px 8px;
    font-size: 16px;
}

.tableWrap {
    padding-bottom: 40px;
}

.tableHeader {
    padding: 10px 20px 20px 0;
}