@import 'components/global.scss';

main {

  &.home {
    background-size: 600% 600%;
    background: #e7e7e7;
    color: #1A1A1A;

    section[class*="row_"] {
      background: transparent;

      div[class*="content_"] {
        background-color: white;
        padding: 40px;
        box-shadow: 0px 12px 17px 2px rgba(0,0,0,0.35);
        -webkit-box-shadow: 0px 12px 17px 2px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px 12px 17px 2px rgba(0,0,0,0.35);
        border-radius: $section-radius;
      }
    }
  }

  @media (min-width: $mobile){

    &.with-sidebar {

      padding-left: $scale7 + $scale1;

      .blankslate-message {

        margin-left: ($scale11 + $scale2) / 2;

      }
    }
  }

  @media (max-width: $mobile + 1){

    &.with-bottom-nav {

      padding-bottom: $scale7;

    }
  }
}

header {

  margin-bottom: $scale;

}

.col1 {

  @media (min-width: $mobile){

    float: left;
    width: 49%;

  }
}

.col2 {

  @media (min-width: $mobile){

    float: right;
    width: 49%;

  }
}

.title-row {


  &:after {

    content: "";
    display: table;
    clear: both;

  }

  h2 {

    float: left;
    margin-top: 0;

  }

  button {

    float: right;
    margin-top: -0.5em;

  }
}
