@import 'components/global.scss';

.subnav {

  overflow: hidden;
  padding: $scale1 $scale2 0;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;
  background: white;
  border-top: 1px solid $c-border;

  a {

    color: $c-text;
    display: inline-block;
    padding-bottom: $scale;
    margin-right: $scale2;
    text-decoration: none;

    &.active {

      border-bottom: 2px solid $c-primary;

    }
  }
}
