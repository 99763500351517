/* colours */
$c-bg: #f7f7f7;
$c-text: #000;
$c-text-light: lighten($c-text, 20%);
$c-border: darken(white, 5%);
$c-border-dark: lighten($c-text, 52%);
$c-primary: #73B0F4;
$c-dark: #333440;
$off-blue: #d3dde7;
$off-black: #1A1A1A;
$off-white: #e9e9e9;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #d95565;
$c-green: #8CC57D;
$c-blue: #73B0F4;
$c-darkblue: #3a4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-darkpurple: #556CB6;
$c-rosybrown: #bc8f8f;
$c-shellblue: #497285;