@import 'components/global.scss';

.logo {

  display: block;
  width: $scale9;
  height: $scale3;
  margin: 0 auto $scale2;
  outline: none;

}
