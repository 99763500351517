@import 'components/global.scss';

.article {

  padding: $scale3 0;

  h1 {

    color: $off-black;
    margin-bottom: $scale;

  }

  h2 {

    color: $off-black;
    font-size: $scale1;
    margin: $scale3 0 $scale 0;

  }

  h3 {

    color: $off-black;
    font-size: $scale;
    margin-bottom: $scale;

  }

  @media (min-width: $mobile){

    padding: $scale6 0;

  }
}
