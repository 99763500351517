@import 'components/global.scss';

.user {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $scale2;

}

.name {

  float: right;
  opacity: 0.5;
  margin-bottom: 0.25em;
  font-size: $scale-1;

}

.hoverNav {

  float: right;
  clear: right;

}