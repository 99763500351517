.clearfix {

  content: "";
  display: table;
  clear: both;

}

.react-kanban-column {
  min-height: 500px !important;
  min-width: 350px !important;
}

.ant-table-wrapper {
  margin-top: 20px !important;
}

.ant-table-wrapper tr.ant-table-expanded-row > .ant-table-cell {
  background-color: $off-blue;
}

.ant-pagination {
  margin: 36px 0 !important;
}

.ant-select {
  width: 100% !important;
  
  .ant-select-selector {
    height: 100% !important;
  
    .ant-select-selection-item {
      line-height: 52px;
    }
  }
}

@media (min-width: 300px){
  .ant-result .ant-result-extra .ant-btn {
    width: 48% !important;
    padding: 0px;
  }
}

.full-width {

  max-width: 100%;
  width: 100%;

  > * {

    width: 100%;
    max-width: 100%;

  }
}

.restrict-width {

  max-width: $max-width;
  margin: 0 auto;

}

.no-padding {

  padding: 0 !important;

}

img {

  max-width: 100%;
  line-height: 0;

}

.align-x {

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);

}

.align-y {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);

}

.align-xy {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

}

.datepicker-modal {
  height: 300px;

  form {
    overflow: inherit;
  }
}

.align-center {

  text-align: center;

}

.show {

  display: block;

}

.hide {

  display: none;

}

.ant-picker {
  height: 52px;
}
