@import 'components/global.scss';

.auth {

  background: #ffffff;
  background-size: 600% 600%;

  & > div > section {
    background-color: transparent;

    div[class*="content"] {
      background-color: white;
      padding: 40px;
      border-radius: 0px;
      box-shadow: 0px 12px 17px 2px rgba(0,0,0,0.35);
      -webkit-box-shadow: 0px 12px 17px 2px rgba(0,0,0,0.35);
      -moz-box-shadow: 0px 12px 17px 2px rgba(0,0,0,0.35);
    }
  }

  header + section > button {

    display: block;
    margin: -$scale auto 0;
  
  }

  form {

    clear: both;
    margin-bottom: $scale1;

    span {

      display: block;
      width: 100%;
      text-align: center;
      margin-top: $scale1;
  
    }
  }

  footer {
    background: #ffffff;
    border-top: none;
  }
}

@media (max-width: $mobile){

  .auth {
    background-color: $off-blue;
  }

}
