.btnMenu {
    height: 66px;
}

.totalWrap {
    padding: 10px 0;
}

.scrollWrap {
    overflow-y: auto;
}