@import 'components/global.scss';

.wrapper {
  border-left: 6px solid;
}

.statTwo {

  position: relative;
  text-align: left;
  min-height: $scale4;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.iconWrap {

  width: 60px;
  height: 60px;
  padding: 16px 14px;
  border-radius: 4pc;

}

.rosyBrownBorder {
  border-color: $c-rosybrown;
}

.rosyBrownBg {
  background-color: rgba(188, 143, 143, 0.4);
}

.rosyBrownIcon {
  color: $c-rosybrown;
}

.shellBlueBorder {
  border-color: $c-shellblue;
}

.shellBlueBg {
  background-color: rgba(73, 114, 133, 0.4);
}

.shellBlueIcon {
  color: $c-shellblue;
}

.darkBlueBorder {
  border-color: $c-darkblue;
}

.darkBlueBg {
  background-color: rgba(58, 68, 136, 0.4);
}

.darkBlueIcon {
  color: $c-darkblue;
}

.orangeBorder {
  border-color: $c-orange;
}

.orangeBg {
  background-color: rgba(240, 170, 97, 0.4);
}

.orangeIcon {
  color: $c-orange;
}

.content {

  margin-left: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  .value {

    font-size: $scale4;
    font-weight: 300;
  
  }

  .label {

    font-size: $scale;
    color: lighten($c-text, 10%);
  
  }
}

.change {

  position: absolute;
  top: 50%;
  right: $scale1;
  font-size: 0.9em;
  padding-top: 1em;
  text-align: center;
  background-size: 1em 1em;
  background-position: top center;
  transform: translateY(-50%);

}

.up { background-image: url("./icons/ico-up-green.svg"); }
.down { background-image: url("./icons/ico-down-red.svg"); }


